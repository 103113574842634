import { CRUD, http } from "@/api";

export const UnidadeOrganizacional = new CRUD("/core/unidades-organizacionais");

export const NaturezaDespesa = new CRUD("/core/naturezas-despesa");

export const Item = new CRUD("/core/itens");
export const Habilidade = new CRUD("/core/habilidades");

export const Categoria = new CRUD("/core/categorias");

export const PDTIC = new CRUD("/core/pdti");

export const Membro = new CRUD("/core/membros");

export const Usuario = new CRUD("/users/manage");

export const Task = new CRUD("/core/tasks");

export const Download = new CRUD("/core/download");

export const Relatorio = new CRUD("/relatorios/export-data");

export const GenerateMinuta = new CRUD("/relatorios/generate-minuta");

export const TemplateUpload = new CRUD("/core/template-upload");

export const Template = new CRUD("/core/template");

PDTIC.aberto = function() {
  return http.get(`${this.uri}/aberto/`);
};

Membro.add = function(username, pdti, tipo, uo) {
  return http.post(`${this.uri}/add/`, {
    username,
    pdti,
    tipo,
    uo,
  });
};

Membro.removeRole = function(username, pdti, tipo) {
  return http.post(`${this.uri}/remover/`, {
    username,
    pdti,
    tipo,
  });
};
