<template>
<v-data-table class="elevation-1" :items="data" :headers="headers" :loading="tableLoading">
    <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small class="mr-2" @click="edit(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small @click="remove(item)">
                    mdi-delete
                </v-icon>
            </template>
            <span>Remover</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small @click="clone(item)">
                    mdi-content-copy
                </v-icon>
            </template>
            <span>Clonar</span>
        </v-tooltip>
    </template>
    <template v-slot:item.preco-medio="{ item }">
        <div v-for="(precoMedioItem, index) in item.precos_medios_item" :key="index">
            {{
            Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(precoMedioItem.preco_medio)
            }}
        </div>
    </template>

    <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Materiais e Serviços</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="$state.user.is_superuser" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Novo</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline"></span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <CategoriaAutoComplete v-model="Item.categoria" :error-messages="errors['categoria']" />
                                    </v-row>
                                    <v-row>
                                        <NaturezaDespesaAutoComplete v-model="Item.natureza_despesa" :error-messages="errors['natureza_despesa']" />
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="Item.nome" label="Nome" :error-messages="errors['nome']"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-textarea v-model="Item.descricao" label="Descrição" :error-messages="errors['descricao']"></v-textarea>
                                    </v-row>
                                    <div v-if="Item.precos_medios_item && Item.precos_medios_item.length > 0">
                                        <v-row v-for="(preco_medio_item, index) in Item.precos_medios_item" :key="index">
                                            <v-money v-model="preco_medio_item.preco_medio" label="Preço médio" :error-messages="errors['precos_medios_item']"></v-money>
                                        </v-row>
                                    </div>
                                    <div v-else>
                                        <v-row>
                                            <v-money v-model="Item.preco_medio" label="Preço médio" :error-messages="errors['preco_medio']"></v-money>
                                        </v-row>
                                    </div>
                                    
                                    <v-row>
                                        <v-text-field v-model="Item.catmat_catser" label="CATMAT/CATSER" :error-messages="errors['catmat_catser']"></v-text-field>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn :loading="saving" color="darken-1" text @click="save">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
    </template>
</v-data-table>
</template>

<script>
import {
    Item
} from "@/api/core";
import CategoriaAutoComplete from "@/components/CategoriaAutoComplete";
import NaturezaDespesaAutoComplete from "@/components/NaturezaDespesaAutoComplete";
import VMoney from "@/components/VMoney";
export default {
    name: "Item",
    components: {
        NaturezaDespesaAutoComplete,
        CategoriaAutoComplete,
        "v-money": VMoney,
    },
    data: () => ({
        data: [],
        headers: [{
                text: "CATMAT/CATSER",
                value: "catmat_catser",
                align: "center",
            },
            {
                text: "Nome",
                value: "nome",
                align: "left",
            },
            {
                text: "Categoria",
                value: "categoria_nome",
                align: "center",
            },
            {
                text: "Preço Médio",
                value: "preco-medio",
                align: "center",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                align: "end",
            },
        ],
        Item: {},
        dialog: false,
        saving: false,
        tableLoading: false,
        errors: [],
    }),
    methods: {
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.Item = {};
            });
        },
        save() {
            this.saving = true;
            this.errors = [];
            const _then = () => {
                this.saving = false;
                this.close();
                this.fetch();
            };
            const _catch = (error) => {
                this.saving = false;
                this.errors = error.response.data;
                if (this.errors["non_field_errors"]) {
                    this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
                } else {
                    this.$emitter.emit("alert:error", this.errors);
                }
            };

            if (this.Item["id"]) {
                let id = this.Item["id"];

                Item.update(id, this.Item)
                    .then(_then)
                    .catch(_catch);
            } else {
                Item.post(this.Item)
                    .then(_then)
                    .catch(_catch);
            }
        },
        clone(item) {
            this.Item = Object.assign({}, item);
            delete this.Item["id"];
            this.dialog = true;
        },
        edit(item) {
            this.Item = Object.assign({}, item);
            this.dialog = true;
        },

        remove(item) {
            if (window.confirm("Deseja realmente excluir?")) {
            this.tableLoading = true;
            Item.remove(item["id"]).then(() => {
                this.tableLoading = false;
                this.fetch();
            })
            .catch((error) => {
                this.tableLoading = false;
                this.errors = error.response.data;
                if (this.errors["non_field_errors"]) {
                    this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
                } else {
                    this.$emitter.emit("alert:error", this.errors);
                }
            });
            }
        },
        fetch() {
            this.tableLoading = true;
            Item.list().then((response) => {
                this.data = response.data;
                this.tableLoading = false;
            });
        },
    },
    mounted() {
        this.fetch();
    },
};
</script>
