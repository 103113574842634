<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-text="descricao"
    item-value="id"
    :filter="filter"
    :loading="loading"
    label="Categoria"
    dense
  >
    <template v-slot:item="{ item }">
      <v-row dense>
        <v-col cols="12">
          <v-card max-width="500" elevation="0" color="transparent">
            <v-card-title class="item-text">
              {{ item.nome }}
            </v-card-title>
            <v-card-subtitle class="item-text">{{
              item.descricao
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { Categoria } from "@/api/core";
export default {
  name: "CategoriaAutoComplete",
  data: () => ({
    items: [],
    search: null,
    loading: false,
  }),
  methods: {
    filter(item, queryText) {
      const descricao = item.descricao.toLowerCase();
      const search = queryText.toLowerCase();
      return descricao.indexOf(search) > -1;
    },
  },
  mounted() {
    this.loading = true;
    Categoria.list()
      .then((response) => {
        this.items = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style lang="scss">
.item-text {
  font-size: 0.8125rem !important;
}
</style>