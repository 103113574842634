<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    :item-text="getText"
    item-value="id"
    :filter="filter"
    :loading="loading"
    label="Natureza de Despesa"
    dense
  >
    <template v-slot:item="{ item }">
      <v-row dense>
        <v-col cols="12">
          <v-card max-width="500" elevation="0" color="transparent">
            <v-card-title class="item-text">
              {{ getText(item) }}
            </v-card-title>
            <v-card-subtitle class="item-text">{{
              item.descricao
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { NaturezaDespesa } from "@/api/core";
export default {
  name: "NaturezaDespesaAutoComplete",
  data: () => ({
    items: [],
    search: null,
    loading: false,
  }),

  methods: {
    getText(item) {
      return `${item.codigo} - ${item.descricao}`;
    },
    filter(item, queryText) {
      const descricao = item.descricao.toLowerCase();
      const search = queryText.toLowerCase();
      return descricao.indexOf(search) > -1;
    },
  },
  mounted() {
    this.loading = true;
    NaturezaDespesa.list()
      .then((response) => {
        this.items = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
